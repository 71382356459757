import React from "react"
import { HiArrowNarrowRight } from "react-icons/hi"
import { Link } from "react-scroll"

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#557a95]'>
      {/* container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#b1a296]'>Hi, my name is</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
          Yong Cho
        </h1>
        {/* original color #8892b0 */}
        <h2 className='text-4xl sm:text-7xl font-bold text-[#b1a296]'>
          I'm a Full Stack Developer.
        </h2>
        <p className='text-[#ccd6f6] py-4 max-w-[700px]'>
          I'm a full-stack developer specializing in building (and occasionally
          designing) exceptional digital experiences. Currently, I'm focused on
          building responsive full-stack web applications.
        </p>
        <div>
          <button
            className='text-white group border-2 px-6 py-3 my-2 flex
            items-center hover:bg-[#b1a296] hover:border-[#b1a296]'
          >
            <Link to='work' smooth={true} duration={500}>
              View Work
            </Link>
            <span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-3' />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Home

import React from "react"

const Contact = () => {
  return (
    <div
      name='contact'
      className='w-full h-screen bg-[#557a95] flex justify-center items-center p-4'
    >
      <form
        method='POST'
        action='https://getform.io/f/9e1af5be-3f78-47c4-b6a5-0d3cd41917d4'
        className='flex flex-col max-w-[600px] w-full'
      >
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#b1a296]'>
            Contact
          </p>
          <p className='text-gray-300 py-4'>
            // Sumbit the form below or shoot me an email - yong-cho@outlook.com
          </p>
        </div>
        <input
          className='bg-[#ccd6f6] p-2'
          type='text'
          placeholder='Name'
          name='name'
        />
        <input
          className='my-4 p-2 bg-[#ccd6f6]'
          type='email'
          placeholder='Email'
          name='email'
        />
        <textarea
          className='bg-[#ccd6f6] p-2'
          name='message'
          rows='10'
          placeholder='Message'
        ></textarea>
        <button className='text-white border-2 hover:bg-[#b1a296] hover:border-[#b1a296] px-4 py-3 my-8 mx-auto flex items-center'>
          Let's Collaborate
        </button>
      </form>
    </div>
  )
}

export default Contact
